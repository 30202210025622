import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../index';

export interface ICurrentChildAppState {
    /**
     * undefined 表示当前没有子应用
     */
    currentChildAppName?: string;
}

const initialState: ICurrentChildAppState = {
    currentChildAppName: undefined
};

/**
 * 当前子应用的相关的信息状态管理
 */
export const currentChildAppSlice = createSlice({
    name: 'currentChildApp',
    initialState,
    reducers: {
        setCurrentChildApp: (state, data: { payload: ICurrentChildAppState; type: string; }) => {

            state.currentChildAppName = data.payload.currentChildAppName;
        }

    }
});

export const { setCurrentChildApp } = currentChildAppSlice.actions;

export const getCurrentChildApp =  (state: RootState): ICurrentChildAppState  => state.currentChildApp;

export default currentChildAppSlice.reducer;
