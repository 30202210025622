import React from 'react';

const CompError: React.FC<{ errSymbol: string;message: string; }> = (props ) => {

    return (
        <div style={{
            backgroundColor: '#fff',
            minHeight: '780px',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center'
        }}
        >
            <h2>{props.errSymbol}</h2>
            <p>{props.message}</p>
        </div>
    );

};

export default CompError;
