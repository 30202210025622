import { Session } from '@reolink/web.fe.http-client';
import CONFIG from '@/config';
const regArr = /https:\/\/(.*)/.exec( CONFIG.officialDomain );
const cookieDomain = regArr ? ( regArr[1] ?? 'reolink.com' ) : 'reolink.com';
const defaultSessionOptions = {
    clientId: CONFIG.clientId,
    cookieDomain: cookieDomain,
    baseUrl: CONFIG.baseUrl,
    handleLogin() {

        const redirect = location.href;
        location.href = `${CONFIG.accountDomain}/login?redirect=${redirect}`;

    }
};
const sessionInstance = new Session( defaultSessionOptions );
export { sessionInstance };
