import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '..';

export const permissionSlice = createSlice({
    name: 'permission',
    initialState: {
        canGo: true
    },
    reducers: {
        setCanGo: (state, data: { payload: { canGo: boolean; };type: string; }) => {
            state.canGo = data.payload.canGo;
        }
    }
});
export const { setCanGo } = permissionSlice.actions;
export const getCanGo =  (state: RootState): boolean  => state.permission.canGo;
