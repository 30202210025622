import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../index';

export interface IPageState{
    pageTitle: string;
    cateTitle: string;
    currentPage: Record<string, any>;
    pageList: any[];
    breadCrumbList: string[];
    defaultSidebarCollapsed?: boolean;
    version?: string;
}
const initialState: IPageState = {
    pageTitle: '',
    cateTitle: '',
    currentPage: {},
    pageList: [],
    breadCrumbList: [],
    defaultSidebarCollapsed: false,
    version: ''
};
export const pageSlice = createSlice({
    name: 'currentPage',
    initialState,
    reducers: {
        setPageData: (state, data: { payload: IPageState;type: string; }) => {

            state.pageTitle = data.payload.pageTitle;
            state.cateTitle = data.payload.cateTitle;
            state.currentPage = data.payload.currentPage;
            state.pageList = data.payload.pageList;
            state.breadCrumbList = data.payload.breadCrumbList;
            state.defaultSidebarCollapsed = data.payload.defaultSidebarCollapsed;
            state.version = data.payload.version ?? '';

        }

    }
});
export const { setPageData } = pageSlice.actions;

export const getCurrentPageData =  (state: RootState): IPageState  => state.page;

export default pageSlice.reducer;
