class Config {
    /**
     * 当前服务器环境
     */
    public mode = import.meta.env.MODE;

    public clientId: string = process.env.VITE_CLINENT_ID!;

    public officialDomain: string = process.env.VITE_OFFICIAL_SITE!;

    public accountDomain: string = process.env.VITE_OFFICIAL_ACCOUNT_SITE!;

    public apiDomain: string = process.env.VITE_API!;

    public baseUrl: string = this.apiDomain + '/v1.0';

    /**
     * 微前端服务名
     */
    public serviceName: string = 'microApp';

    public baseOrigin: string = process.env.VITE_BASE_ORIGIN!;
 
    public cloudAdmincpDomain: string = this.baseOrigin + process.env.VITE_CLOUD_ADMINCP_SITE!;

    public adminDomain: string = this.baseOrigin + process.env.VITE_ADMINCP_SITE!;

    public blogAdmincpDomain: string = this.baseOrigin + process.env.VITE_BLOG_ADMINCP_SITE!;

    public supportLogCenterDomain: string = this.baseOrigin + process.env.VITE_SUPPORT_LOG_CENTER!;

    public dev: string = process.env.VITE_DEV!;

    public currentApp: string = '';

}

export default new Config();
