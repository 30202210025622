import '@/modules/Env';
import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import store from '@/store';
import config from '@/config';
import { replaceImport } from './utils/mainApp';
import microApp from '@micro-zoe/micro-app';
import './index.less';
import { sessionInstance } from './client/session';
import { Spin } from 'antd';
import CompError from './pages/error';

const CompApp = React.lazy(() => import('./App'));
async function init(): Promise<void> {

    // ! 涉及模块联邦时需使用动态引入 import('./modules')
    const { pages,
        profile,
        permissionChecker,
        pageHistory,
        pageFavorites,
        childApps,
        settings
    } = await import('./modules');

    microApp.start({
        'disable-memory-router': true, // 关闭虚拟路由系统
        'disable-patch-request': true, // 关闭对子应用请求的拦截
        preFetchApps: [
            // { name: 'cloud-admincp', url: config.admincpDomain }
        ],
        plugins: {
            modules: {
                // appName即应用的name值
                'dev-app': [{ //for vite
                    loader( code ) {

                        let codeNew = code;

                        codeNew = replaceImport({ code, url: config.dev });
                        
                        console.log('开发者模式替换相对链接', codeNew);
                        return codeNew;
                    }
                }],
            }
        },
        fetch: async ( url: string, options: Record<string, any> ): Promise<string> => {

            if ( url.includes( 'reolink' )) {

                const data = await window.fetch( url, { ...options, credentials: 'include' });

                let text;
                if (url.includes('/child-app-xxd/assets/index')) {

                    text = data.text();
                    console.log(data, text);
                }
                return text ? text : data.text();
            
            }
            
            return window.fetch( url, options ).then( res => res.text());
        
        }
    });
    try {

        await sessionInstance.init();
        await profile.init();
        await permissionChecker.init();
        await settings.init();
        await childApps.init(settings.getChildApps());
        await pages.init(settings.getPages());

        try {

            await Promise.all([
                pageHistory.init(),
                pageFavorites.init()
            ]);
        }
        catch (e) {

            console.error('获取用户系统设置失败', e);
        }
    }
    catch ( error ) {

        console.error( error );
        const symbol = (error as any).response.data.error.symbol;
        const message = (error as any).response.data.error.message;

        if (symbol === 'token_expired') {
            sessionInstance.refreshToken().then(() => {
                setTimeout(() => {
                    window.location.reload();
                }, 1000);
            }).catch(console.error);
        }
        
        ReactDOM.render(
            <CompError
                errSymbol={ symbol }
                message={ message }
            />
            , document.getElementById( 'root' )
        );
        
        return;
    }

    ReactDOM.render(
        (
            <Provider store={ store }>
                <BrowserRouter>
                    <Suspense
                        fallback={ (
                            <Spin
                                spinning={ true }
                                tip={ 'loading...' }
                            >
                                <div style={{ width: '100%', minHeight: 1200 }}></div>
                            </Spin>
                        ) }
                    >
                        <CompApp />
                    </Suspense>
                </BrowserRouter>
            </Provider>
        ),
        document.getElementById( 'root' )
    );

}
init().catch( console.error );
