interface IReplaceConfig {
    code: string;
    url: string;
    appName?: string;
}

export const replaceImport = ( config: IReplaceConfig ): string => {

    let codeNew = config.code;

    const reg = new RegExp( `(from|import)(\\s*['"])(\\/${config.appName ?? 'child-app-[^/]*'}\\/)`, 'g' );

    codeNew = codeNew.replace( reg, ( all, $1: string, $2: string, $3: string ) => {

        const newAppName = config.appName ?? $3.replaceAll( '/', '' );

        return all.replace( `/${newAppName}/`, `${config.url}/${newAppName}/` );

    });
    const reg1 = new RegExp( `(from|import\\()(\\s*['"])(\\/${config.appName ?? 'child-app-[^/]*'}\\/)`, 'g' );
    codeNew = codeNew.replace( reg1,
        ( all: string, $1: string, $2: string, $3: string ) => {

            const newAppName = config.appName ?? $3.replaceAll( '/', '' );

            const result = all.replace( $3, new URL( $3, `${config.url}/${newAppName}/` ) as any );

            return result;

        }
    );

    return codeNew;

};
