import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import { pageSlice, permissionSlice } from './slice';
import { currentChildAppSlice } from './slice/currentChildApp';

const store = configureStore({

    reducer: {

        page: pageSlice.reducer,
        permission: permissionSlice.reducer,
        currentChildApp: currentChildAppSlice.reducer
    }

});

export default store;

// 从 store 本身推断出 `RootState` 和 `AppDispatch` 类型
export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;
// eslint-disable-next-line @typescript-eslint/naming-convention
export type AppThunk<ReturnType = void> = ThunkAction<
	ReturnType,
	RootState,
	unknown,
	Action<string>
>;

/**
 *
 * 官网demo
 * https://github.com/reduxjs/cra-template-redux-typescript/tree/master/template/src
 *
 **/
